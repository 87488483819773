<template>
    <div class="flex justify-center items-center mt-10">
        <div class="bg-white p-2 rounded-lg w-6/12 shadow-sm">
            <div class="text-center my-4">
                <h2 class="text-lg font-bold">
                    Crear Incidente
                </h2>
            </div>
            <div class="flex flex-wrap content-around">
                <div class="w-full flex flex-col items-center py-5 px-2 ">
                    <div class="px-2 w-full flex flex-col">
                      <div class="mb-4" style="width: 100% !important;">
                          <label class="font-medium text-sm">Fallo</label>
                          <Dropdown
                            v-model="model.base"
                            :options="fallosTutela"
                            class="w-full"
                            optionValue="id"
                            optionLabel="observacion"
                            placeholder="Seleccione"
                            >
                          <template #value="slotProps">
                            <div class="country-item country-item-value" v-if="slotProps.value">
                              <div v-if="fallos.find(a => a.id === slotProps.value)">
                                <div>{{ fallos.find(a => a.id === slotProps.value).observacion }}</div>
                              </div>
                            </div>
                            <span v-else>
                              {{slotProps.placeholder}}
                            </span>
                          </template>
                          <template #option="slotProps">
                            <div class="flex">
                              <div class="w-2/12">
                                <p style="color: #7F7E81" class="text-xs">
                                  ID:
                                </p>
                                <p class="text-normal">
                                  {{ slotProps.option.id }}
                                </p>
                              </div>
                              <div class="w-5/12">
                                <p style="color: #7F7E81" class="text-xs">
                                  Observaciones:
                                </p>
                                <p class="text-normal">
                                  {{ slotProps.option.observacion.slice(0, 40) }}...
                                </p>
                              </div>
                            </div>
                          </template>
                          </Dropdown>
                          <MessageError :text="errors.base"/>
                      </div>
                    </div>
                    <div class="px-2 w-full flex flex-col">
                      <div class="mb-4" style="width: 100% !important;">
                          <label class="font-medium text-sm">Tipo de incidente</label>
                          <Dropdown
                            v-model="model.tipoincidencia"
                            :options="tipoIncidentes"
                            class="w-full"
                            optionValue="id"
                            optionLabel="nombre"
                            placeholder="Seleccione"
                            >
                          <template #value="slotProps">
                            <div class="country-item country-item-value" v-if="slotProps.value">
                              <div v-if="tipoIncidentes.find(a => a.id === slotProps.value)">
                                <div>{{ tipoIncidentes.find(a => a.id === slotProps.value).nombre }}</div>
                              </div>
                            </div>
                            <span v-else>
                              {{slotProps.placeholder}}
                            </span>
                          </template>
                          <template #option="slotProps">
                            <div class="flex">
                              <div class="w-2/12">
                                <p style="color: #7F7E81" class="text-xs">
                                  ID:
                                </p>
                                <p class="text-normal">
                                  {{ slotProps.option.id }}
                                </p>
                              </div>
                              <div class="w-5/12">
                                <p style="color: #7F7E81" class="text-xs">
                                  Tipo:
                                </p>
                                <p class="text-normal">
                                  {{ slotProps.option.nombre }}
                                </p>
                              </div>
                            </div>
                          </template>
                          </Dropdown>
                          <MessageError :text="errors.base"/>
                      </div>
                    </div>
                    <div class="px-2 w-full flex flex-col">
                      <div class="mb-4">
                          <label class="font-medium text-sm">Observaciones</label>
                          <Textarea
                          rows="3" cols="30"
                          class="w-full"
                          inputClass="w-full"
                          placeholder="Escribir..."
                          v-model="model.observacion"
                          />
                          <MessageError :text="errors.observacion"/>
                      </div>
                    </div>
                    <div class="flex gap-4 px-2">
                      <div class="mb-4">
                        <label class="font-medium text-sm">Fecha de notificacion</label>
                        <input class="p-inputtext w-full"
                              type="datetime-local"
                              v-model="model.fechaNotificacion"
                        >
                        <MessageError :text="errors.fechaNotificacion"/>
                      </div>
                      <div class="mb-4">
                        <label class="font-medium text-sm">Fecha de vencimiento</label>
                        <input class="p-inputtext w-full"
                              type="datetime-local"
                              v-model="model.fechaVencimiento"
                        >
                        <MessageError :text="errors.fechaVencimiento"/>
                      </div>
                    </div>
                    <div class="px-2 w-full">
                      <div style="max-height: 150px; overflow-y: auto;">
                        <div class="flex justify-between" v-for="(item, key) in adjuntos" :key="key">
                          <div class="flex mt-2">
                            <i class="pi pi-file-pdf mr-2 text-black"></i>
                            <p>{{ item.fileName }}</p>
                          </div>
                          <div>
                            <button><i class="pi pi-trash mr-2 text-red-600"></i></button>
                          </div>
                        </div>
                      </div>
                      </div>
                    <div class="px-2 mt-4 w-full flex flex-col">
                      <button label="Show" icon="pi pi-external-link" @click="openMaximizable" class="w-full rounded-full py-2 bg-white border border-solid border-gray-400">
                        Agregar nuevo soporte
                      </button>
                      <Dialog header="Agregar soportes" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                          <div>
                            <div>
                              <label class="text-sm" for="titulo">Titulo</label>
                              <input type="text" v-model="title" id="titulo" placeholder="Escriba aqui el titulo del documento"
                              class="text-sm mt-2 block placeholder-gray-300 p-2  w-full text-sm text-gray-900 rounded-lg border border-gray-300 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                            </div>
                            <div class="my-2">
                              <label class="text-sm">Adjunte el documento</label>
                              <div class="mt-2">
                                <button class="w-full">
                                  <FilePond ref="pond"/>
                                </button>
                              </div>
                            </div>
                          </div>
                          <template class="text-center" #footer>
                              <Button label="Guardar" class="w-full" @click="closeMaximizable" autofocus />
                          </template>
                      </Dialog>
                    </div>
                </div>
                <div class="w-full flex justify-between px-4 mt-2 mb-9">
                    <Button  label="Cancelar"
                            class="bg-blue-900 p-button-outlined"
                            style="width: 244px;"
                            @click="$router.go(-1)"
                    />
                    <Button  label="Crear"
                            class="bg-blue-900"
                            style="width: 244px;"
                            type="button"
                            @click="handleNext"
                    />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import storeIncidencias from '../../../../store/incidencia.store'
import storeFallos from '../../../../store/fallos.store'
import FilePond from '../../../uploadFilePoundNew/index.vue'
import { onMounted, ref, computed } from 'vue'
import Swal from 'sweetalert2'
import { useToast } from 'primevue/usetoast'
import { useField, useForm } from 'vee-validate'
import * as yup from 'yup'
import { useRouter, useRoute } from 'vue-router'
import Dropdown from 'primevue/dropdown'
import filepondStore from '../../../../store/filepond.store'
export default {
  name: 'crearIncidencia',
  components: {
    Dropdown,
    FilePond
  },
  setup () {
    const adjunto = computed(() => filepondStore.getters.adjunto)
    const adjuntos = ref([])
    const title = ref('')
    const pond = ref()
    const toast = useToast()
    const router = useRouter()
    const route = useRoute()
    const bases = ref([])
    const displayMaximizable = ref(false)
    const idTutela = route.query.idTutela
    const fallosTutela = ref()
    const tipoIncidentes = [
      {
        nombre: 'Incidente tipo Apertura Formal',
        id: 1
      },
      {
        nombre: 'Incidente tipo requerimiento',
        id: 2
      }
    ]
    const tipos = computed(
      () => storeIncidencias.getters.tipos.rows
    )
    const fallos = computed(
      () => storeFallos.getters.fallos
    )
    const openMaximizable = () => {
      displayMaximizable.value = true
    }
    const closeMaximizable = () => {
      pond.value.onSubmitSupport().then(() => {
        adjuntos.value.push({
          urlPath: adjunto.value.urlPath,
          fileName: title.value
        })
        displayMaximizable.value = false
        title.value = ''
      })
    }
    const schema = yup.object({
      tipo: yup.number().required('Seleccione al menos un tipo').label(''),
      base: yup.number().required('Seleccione al menos un caso').label(''),
      observacion: yup.string().required('Este campo es requerido').label(''),
      fechaNotificacion: yup.date().required('Este campo es requerido'),
      fechaVencimiento: yup.date().required('Este campo es requerido'),
      tipoincidencia: yup.number().required('Seleccione al menos un tipo').label('')
    })
    const { errors, values: model, handleSubmit } = useForm({
      validationSchema: schema
    })
    useField('tipo', null, { initialValue: 3 })
    useField('tipoincidencia')
    useField('base')
    useField('observacion')
    useField('fechaNotificacion')
    useField('fechaVencimiento')
    const handleNext = handleSubmit((values) => {
      if (!values) return console.error('Error => validation')
      values.paths = adjuntos.value
      storeIncidencias.dispatch('createIncidencia', values).then(({ data }) => {
        if (data.error) {
          toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El documento ya existe', life: 3000 })
        } else {
          Swal.fire({
            title: 'Exito',
            text: 'Incidente registrado con exito',
            icon: 'success',
            showCancelButton: false,
            confirmButtonText: 'Ok'
          }).then((result) => {
            if (result.isConfirmed) {
              router.go(-1)
            }
          })
        }
      })
    })

    onMounted(() => {
      storeIncidencias.dispatch('getTiposIncidencia')
      storeIncidencias.dispatch('getPaginate')
      storeFallos.dispatch('search').then(() => {
        if (route.query.base) {
          fallosTutela.value = fallos.value.filter(a => a.base === parseInt(route.query.idTutela))
          model.base = parseInt(route.query.base)
        } else {
          fallosTutela.value = fallos.value
        }
      })
    })

    return {
      errors,
      model,
      handleNext,
      tipos,
      bases,
      openMaximizable,
      closeMaximizable,
      displayMaximizable,
      fallos,
      title,
      pond,
      adjuntos,
      idTutela,
      fallosTutela,
      tipoIncidentes
    }
  }
}
</script>

<style scoped>
::v-deep(.p-fileupload-buttonbar) {
  display: none;
}
::v-deep(.p-fileupload .p-fileupload-content ) {
  border: none;
}
::v-deep(.p-button.p-fileupload-choose):hover {
  background-color: white;
  color: black;
  font-weight: 600;
}
::v-deep(.p-button.p-fileupload-choose):focus {
  box-shadow: none;
}
::v-deep(.p-button .p-button-icon-left) {
  display: none;
}
::v-deep(.p-button:enabled:hover .p-dialog .p-dialog-footer button) {
  width: 100% !important;
}
::placeholder {
  color: #ced4da;
}

</style>
